/**
 * External modules
 */
import { atom } from "recoil";

/**
 * Type modules
 */
import type { EditType } from "../components/viewer/menu/MenuEditMode";
import type { ShapeType } from "../components/viewer/menu/MenuShapeSelect";

export const isEditState = atom({
  key: "is-edit",
  default: true,
});

export const currentEditModeState = atom<EditType>({
  key: "current-edit-mode",
  default: "pen",
});

export const currentPenColorState = atom({
  key: "current-pen-color",
  default: "#000",
});

export const currentPenWidthState = atom({
  key: "current-pen-width",
  default: 4,
});

export const currentShapeState = atom<ShapeType>({
  key: "current-shape",
  default: "line",
});
