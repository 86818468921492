/**
 * Internal modules
 */
import { PDFPage } from "../modules/pdf";
import { generateArrayFromMemory } from "../modules/wasmMemory";
import { getAspectRatio } from "../utils";

/**
 * Type modules
 */
import type { RefObject, MutableRefObject } from "react";
import type { PDF } from "../modules/pdf";

/**
 * Open PDF file
 * @returns {number} PDF file page count
 */
export const openPDF = (pdfInstance?: PDF): number => {
  if (!pdfInstance) {
    console.warn("No PDF instance to open document.");
    return 0;
  }

  console.log("Opening PDF document...");
  pdfInstance.open();
  console.log("PDF successfully opened!");
  return pdfInstance.getPageCount();
};

/**
 * Close PDF file
 */
export const closePDF = (pdfInstance?: PDF) => {
  if (!pdfInstance) {
    console.warn("No PDF instance to close document.");
    return;
  }

  console.log("Closing PDF...");
  pdfInstance.close();
  console.log("PDF successfully closed!");
};

/**
 * Create and open a page in PDF
 * @param pageIndex target page index to open
 * @param pdfInstance PDF instance reference
 */
export const openPDFPage = (pageIndex: number, pdfInstance?: PDF) => {
  if (!pdfInstance) {
    console.warn("No PDF instance to open page.");
    return;
  }

  if (pageIndex > pdfInstance.pageCount - 1) {
    console.error("Error openPDFPage: page index out of bounds");
    // TODO throw error
    return;
  }

  const pageInstance = pdfInstance.pages?.[pageIndex];
  if (!pageInstance) {
    console.error("Error openPDFPage: could not load page instance.");
    return;
  }

  if (!pageInstance.isLoaded()) {
    console.log(`Opening PDF page ${pageIndex}...`);
    pageInstance.load();
    console.log(`PDF page ${pageIndex} successfully opened!`);
  }

  return pageInstance;
};

export const closePDFPage = (pageInstance?: PDFPage) => {
  if (!pageInstance) {
    console.warn("No PDFPage instance to close page.");
    return;
  }
  pageInstance.close();
};

export const getPageAspectRatio = (pageInstance?: PDFPage) => {
  if (!pageInstance) {
    console.log("No PDFPage instance to get aspect ratio");
    return null;
  }
  return getAspectRatio(pageInstance.size.width, pageInstance.size.height);
};

/**
 * Render specific PDF page as bitmap
 * @param pageIndex page index that you want to render
 * @param pdfInstance PDF instance reference
 * @param canvasEl target canvas element reference object that you want to show the rendered result
 */
export const renderPage = (
  pageInstanceRef?: MutableRefObject<PDFPage | undefined>,
  canvasElRef?: RefObject<HTMLCanvasElement>,
  containerElRef?: RefObject<HTMLElement>,
  renderScale = 1.0,
) => {
  const pageInstance = pageInstanceRef?.current;
  const canvasEl = canvasElRef?.current;
  const containerEl = containerElRef?.current;
  if (!pageInstance || !canvasEl || !containerEl) {
    console.warn("Required arguments not passed to render!");
    return;
  }

  const ratio = getPageAspectRatio(pageInstance);
  if (!ratio) {
    console.warn("Error while calling getPageAspectRatio");
    return;
  }
  const fullWidth = Math.round(containerEl.clientWidth ?? 0);
  const fullHeight = Math.round((fullWidth / ratio.x) * ratio.y);
  // console.log("fullWidth:", fullWidth, "fullHeight:", fullHeight);
  // console.log("ratio:", ratio);
  canvasEl.width = fullWidth ?? 30 * ratio.x;
  canvasEl.height = fullWidth ? fullHeight : 30 * ratio.y;

  const renderWidth = Math.round(canvasEl.width * renderScale);
  const renderHeight = Math.round(canvasEl.height * renderScale);
  console.log(`render size: ${renderWidth}, ${renderHeight}`);

  pageInstance.render(renderWidth, renderHeight);
  const ctx = canvasEl.getContext("2d");
  if (!ctx) {
    console.error("Can't get context of <canvas> element.");
    return;
  }
  const imageData = ctx.createImageData(renderWidth, renderHeight);
  const pageBitmap = generateArrayFromMemory(
    window.FPDF.HEAPU8,
    pageInstance.memory.bitmapBufferAddress,
    pageInstance.memory.bitmapBufferSize
  );
  // console.log("pageBitmap:", pageBitmap);
  imageData.data.set(pageBitmap);
  createImageBitmap(imageData).then((imageData) => {
    ctx.drawImage(imageData, 0, 0, canvasEl.width, canvasEl.height);
  });
};
