/**
 * External modules
 */
import styled from "styled-components";
import { alignItems, flex, flexDirection, space } from "styled-system";

/**
 * Type modules
 */
import type { AlignItemsProps, FlexProps, FlexDirectionProps, SpaceProps } from "styled-system";

export const Section = styled.section<AlignItemsProps & FlexProps & FlexDirectionProps & SpaceProps>`
  display: flex;
  ${alignItems}
  ${flex}
  ${flexDirection}
  ${space}
`;
