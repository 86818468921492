/**
 * External modules
 */
import React, { useCallback } from "react";
import { BsLayoutThreeColumns } from "react-icons/bs";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { space } from "styled-system";

/**
 * Internal modules
 */
import { gridSizeState } from "../../../states/layout";

/**
 * Type modules
 */
import type { SpaceProps } from "styled-system";

const GridSliderWrapper = styled.div<SpaceProps>`
  ${space}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 8px 12px;
  font-size: 12px;

  & > span {
    margin-left: 4px;
    margin-right: 8px;
    font-size: 16px;
  }
`;

export const MenuGridSizeSlider = (props: SpaceProps) => {
  const { ...styles } = props;
  const [gridSize, setGridSize] = useRecoilState(gridSizeState);
  const handleSliderChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setGridSize(Number(e.target.value));
  }, [setGridSize]);

  return (
    <GridSliderWrapper {...styles}>
      <BsLayoutThreeColumns size={18} />
      <span>{gridSize}</span>
      <input type="range" min="1" max="6" value={gridSize} onChange={handleSliderChange} />
    </GridSliderWrapper>
  )
};
