/**
 * External modules
 */
import { useContext } from "react";

/**
 * Internal modules
 */
import { DocumentDataManagerContext } from "../contexts/DocumentDataManagerContext";

export const useDocumentDataManager = () => useContext(DocumentDataManagerContext);
