/**
 * External modules
 */
import React, { useCallback } from "react";
import { BsPencil, BsEraser, BsFonts, BsMagic } from "react-icons/bs";
import styled from "styled-components";
import { compose, width, height, space } from "styled-system";

/**
 * Internal modules
 */
import { Button } from "../../common";

/**
 * Type modules
 */
import type { WidthProps, HeightProps, SpaceProps } from "styled-system";

const Wrapper = styled.div<WidthProps & HeightProps & SpaceProps>`
  ${compose(width, height, space)}
  display: flex;
`;

export type EditType = "pen" | "eraser" | "text" | "shape";
export type MenuEditModeChangeHandler = (editMode: EditType) => void;

interface Props extends WidthProps, HeightProps, SpaceProps {
  editMode: EditType;
  onChange?: MenuEditModeChangeHandler;
}

export const MenuEditMode = (props: Props) => {
  const { editMode, onChange, ...styles } = props;
  const handleClick = useCallback(
    (mode: EditType) => () => {
      onChange?.(mode);
    },
    [onChange]
  );

  return (
    <Wrapper {...styles}>
      <Button
        color="#000"
        hoverBackground="#d4d4d8"
        background={editMode === "pen" ? "#d4d4d8" : "#fff"}
        onClick={handleClick("pen")}
      >
        <BsPencil size={20} />
      </Button>
      <Button
        color="#000"
        hoverBackground="#d4d4d8"
        background={editMode === "eraser" ? "#d4d4d8" : "#fff"}
        onClick={handleClick("eraser")}
      >
        <BsEraser size={20} />
      </Button>
      <Button
        color="#000"
        hoverBackground="#d4d4d8"
        background={editMode === "text" ? "#d4d4d8" : "#fff"}
        onClick={handleClick("text")}
      >
        <BsFonts size={24} />
      </Button>
      <Button
        color="#000"
        hoverBackground="#d4d4d8"
        background={editMode === "shape" ? "#d4d4d8" : "#fff"}
        onClick={handleClick("shape")}
      >
        <BsMagic size={24} />
      </Button>
    </Wrapper>
  );
};
