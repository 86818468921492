/**
 * External modules
 */
import React, { createContext, useRef } from "react";

/**
 * Type modules
 */
import { DocumentDataManager } from "../modules/data";

export const DocumentDataManagerContext = createContext<React.MutableRefObject<DocumentDataManager> | null>(null);

export const DocumentDataManagerProvider = (props: React.PropsWithChildren) => {
  const ref = useRef(new DocumentDataManager(null));
  return (
    <DocumentDataManagerContext.Provider value={ref}>
      {props.children}
    </DocumentDataManagerContext.Provider>
  );
};
