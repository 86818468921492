/**
 * External modules
 */
import React, { useCallback } from "react";
import { BiRectangle, BiCircle } from "react-icons/bi";
import styled from "styled-components";

/**
 * Internal modules
 */
import { Button } from "../../common/Button";

const Wrapper = styled.div`
  display: flex;
`;

const Line = styled.div`
  width: 20px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
`;

export type ShapeType = "line" | "rect" | "circle";
export type MenuShapeSelectHandler = (shape: ShapeType) => void;

interface MenuShapeSelectProps {
  shape: ShapeType;
  onSelect?: MenuShapeSelectHandler;
}

export const MenuShapeSelect = (props: MenuShapeSelectProps) => {
  const handleSelect = useCallback(
    (shape: ShapeType) => () => {
      props.onSelect?.(shape);
    },
    [props]
  );

  return (
    <Wrapper>
      <Button
        background={props.shape === "line" ? undefined : "#fff"}
        color={props.shape === "line" ? "#fff" : "#000"}
        hoverColor="#fff"
        onClick={handleSelect("line")}
      >
        <Line />
      </Button>
      <Button
        background={props.shape === "rect" ? undefined : "#fff"}
        color={props.shape === "rect" ? "#fff" : "#000"}
        hoverColor="#fff"
        onClick={handleSelect("rect")}
      >
        <BiRectangle size={20} />
      </Button>
      <Button
        background={props.shape === "circle" ? undefined : "#fff"}
        color={props.shape === "circle" ? "#fff" : "#000"}
        hoverColor="#fff"
        onClick={handleSelect("circle")}
      >
        <BiCircle size={20} />
      </Button>
    </Wrapper>
  );
};
