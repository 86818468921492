/**
 * External modules
 */
import Konva from "konva";

/**
 * Type modules
 */
import type { Vector2d, IRect } from "konva/lib/types";

interface NodeSize {
  w: number;
  h: number;
}

interface ViewerSize {
  width: number;
  height: number;
}

/**
 * Convert AI processed document coordinate size to rendered document coordinate size
 * @param nodeInfo link position
 * @param processedViewerSize AI processed document coordinate size
 * @param viewerSize rendered document coordinate size
 * @returns converted position
 */
export const convertRelativePosition = (
  nodeInfo: Vector2d & NodeSize,
  processedViewerSize: ViewerSize,
  viewerSize: ViewerSize
): Vector2d & NodeSize => {
  const wCoef = viewerSize.width / processedViewerSize.width;
  const hCoef = viewerSize.height / processedViewerSize.height;

  return {
    x: nodeInfo.x * wCoef,
    y: nodeInfo.y * hCoef,
    w: nodeInfo.w * wCoef,
    h: nodeInfo.h * hCoef,
  };
};

export const haveIntersection = (rect1: IRect, rect2: IRect) => {
  return !(
    rect2.x > rect1.x + rect1.width ||
    rect2.x + rect2.width < rect1.x ||
    rect2.y > rect1.y + rect1.height ||
    rect2.y + rect2.height < rect1.y
  );
};

/**
 * Generate document link object.
 * Link target data stored in `data-link-to` attribute.
 *
 * @param position link shape's position in link layer
 * @param linkTo link target data
 * @returns generated link shape object
 */
export const generateLink = (position: Vector2d & NodeSize, linkTo: string) => {
  const linkShape = new Konva.Circle({
    x: position.x + (position.w + 1) / 2,
    y: position.y + (position.h + 1) / 2,
    radius: (position.w + 1) / 2,
    strokeWidth: 2,
    // stroke: "#f00",
    stroke: "#3730a3",
  });
  linkShape.setAttr("data-link-to", linkTo);

  return linkShape;
};

export const createTransformer = (shape: Konva.Shape) => {
  const transformer = new Konva.Transformer({
    enabledAnchors: ["middle-left", "middle-right", "top-center", "bottom-center"],
    anchorSize: 4,
    nodes: [shape],
  });

  return transformer;
};

export const createLine = (position: Vector2d, lineWidth: number, lineColor: string) => {
  const lineNode = new Konva.Line({
    stroke: lineColor,
    strokeWidth: lineWidth,
    globalCompositeOperation: "source-over",
    lineCap: "round",
    lineJoin: "round",
    points: [position.x, position.y, position.x, position.y],
  });

  return lineNode;
};

export const createEditableText = (nodePosition: Vector2d, color: string, fontSize = 16) => {
  const textShape = new Konva.Text({
    name: "EditableText",
    text: "Text",
    fontSize,
    fill: color,
    x: nodePosition.x,
    y: nodePosition.y,
    draggable: true,
  });

  return textShape;
};

export const createTextEdit = (textNode: Konva.Text) => {
  const stageBound = textNode.getStage()?.container().getBoundingClientRect();
  if (!stageBound) {
    return;
  }

  const offsetX = stageBound.left + textNode.x();
  const offsetY = stageBound.top + textNode.y();

  const textEdit = document.createElement("textarea");
  textEdit.id = "konva-text-editing";
  textEdit.value = textNode.text();
  textEdit.style.position = "absolute";
  textEdit.style.top = `${offsetY}px`;
  textEdit.style.left = `${offsetX}px`;
  textEdit.style.padding = '0px';
  textEdit.style.margin = '0px';
  textEdit.style.width = textNode.width() - textNode.padding() * 2 + 'px';
  textEdit.style.height =
    textNode.height() - textNode.padding() * 2 + 5 + 'px';
  textEdit.style.fontSize = `${textNode.fontSize()}px`;
  textEdit.style.border = "none";
  textEdit.style.background = "none";
  textEdit.style.outline = "none";
  textEdit.style.resize = "none";
  textEdit.style.overflow = "overlay";
  textEdit.style.fontFamily = textNode.fontFamily();
  textEdit.style.textAlign = textNode.align();
  textEdit.style.color = textNode.fill();
  textEdit.style.zIndex = "30";

  return textEdit;
};

export const createRect = (position: Vector2d, fillColor: string) => {
  const rect = new Konva.Rect({
    x: position.x,
    y: position.y,
    width: 0,
    height: 0,
    fill: fillColor,
  });

  return rect;
};
