/**
 * External modules
 */
import React, { useCallback } from "react";
import styled from "styled-components";
import { BsGrid } from "react-icons/bs";
import { BiCarousel } from "react-icons/bi";
import { compose, space, width, height } from "styled-system";

/**
 * Internal modules
 */
import { Button } from "../../common/Button";

/**
 * Type modules
 */
import type { SpaceProps, WidthProps, HeightProps } from "styled-system";

const Wrapper = styled.div<SpaceProps & WidthProps & HeightProps>`
  ${compose(width, height, space)}
  display: flex;
`;

export type LayoutType = "multi" | "single";
export type MenuLayoutChangeHandler = (layout: LayoutType) => void;

interface LayoutSwitchProps extends SpaceProps, WidthProps, HeightProps {
  layout: LayoutType;
  disabled?: boolean;
  onChange: MenuLayoutChangeHandler;
}

export const MenuLayoutSwitch = (props: LayoutSwitchProps) => {
  const { disabled = false, layout, onChange, ...styles } = props;

  const handleChange = useCallback((changedLayout: LayoutType) => () => {
    onChange(changedLayout);
  }, [onChange]);

  return (
    <Wrapper {...styles}>
      <Button
        px="16px"
        disabled={disabled}
        color={layout === "single" ? "#fff" : "#000"}
        hoverBackground={layout === "single" ? undefined : "#cbd5e1"}
        background={layout === "single" ? "#3b82f6" : "#d1d5db"}
        onClick={handleChange("single")}
      >
        <BiCarousel size={20} color={layout === "single" ? "#fff" : "#000"} />
      </Button>
      <Button
        px="16px"
        disabled={disabled}
        color={layout === "multi" ? "#fff" : "#000"}
        hoverBackground={layout === "multi" ? undefined : "#cbd5e1"}
        background={layout === "multi" ? "#3b82f6" : "#d1d5db"}
        onClick={handleChange("multi")}
      >
        <BsGrid size={20} color={layout === "multi" ? "#fff" : "#000"} />
      </Button>
    </Wrapper>
  );
};
