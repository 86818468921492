/**
 * External modules
 */
import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  compose,
  background,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  space,
} from "styled-system";

/**
 * Internal modules
 */
import { ViewerHeader } from "./ViewerHeader";
import { ViewerMenu } from "./ViewerMenu";
import { ViewerContent } from "./ViewerContent";
import { Loading } from "../common";
import { DocumentDataManagerProvider } from "../../contexts/DocumentDataManagerContext";
import { ViewerPagesRefProvider } from "../../contexts/ViewerPagesRefContext";
import { usePDFiumError } from "../../hooks/usePDFiumError";
import * as PDFiumLoader from "../../libs/pdfium-loader";
import { PDF } from "../../modules/pdf";
import { loadingState, loadingMessageState } from "../../states/loading";
import { PDFiumState, PDFiumStateType } from "../../states/pdfium";

/**
 * Type modules
 */
import type {
  BackgroundProps,
  WidthProps,
  MinWidthProps,
  MaxWidthProps,
  HeightProps,
  MinHeightProps,
  MaxHeightProps,
  SpaceProps,
} from "styled-system";

interface ViewerProps
  extends BackgroundProps,
    WidthProps,
    MinWidthProps,
    MaxWidthProps,
    HeightProps,
    MinHeightProps,
    MaxHeightProps,
    SpaceProps {}

const Wrapper = styled.div<ViewerProps>`
  ${compose(background, width, minWidth, maxWidth, height, minHeight, maxHeight, space)}
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
`;

export const Viewer = (props: ViewerProps) => {
  const { ...styles } = props;
  // refs
  const pdfInstance = useRef<PDF>();
  // recoil states
  const [loading, setLoading] = useRecoilState(loadingState);
  const [loadingMessage, setLoadingMessage] = useRecoilState(loadingMessageState);
  const [currentPDFiumState, setCurrentPDFiumState] = useRecoilState(PDFiumState);
  // hooks
  const pdfiumErrorHandler = usePDFiumError();

  /**
   * Load PDFium module
   */
  useEffect(() => {
    if (!loading && currentPDFiumState === PDFiumStateType.NOT_LOADED) {
      setLoading(true);
      setLoadingMessage("PDFium module");
      PDFiumLoader.load({
        onError: pdfiumErrorHandler,
      })
        .then((result) => {
          if (result) {
            setCurrentPDFiumState(PDFiumStateType.NOT_INITIALIZED);
            setLoadingMessage("Initialize PDFium");
            window.FPDF._PDFium_Init();
            setCurrentPDFiumState(PDFiumStateType.READY);
            setLoading(false);
          } else if (currentPDFiumState === PDFiumStateType.NOT_LOADED) {
            // just ignore this state
          } else {
            // script already loaded
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          pdfiumErrorHandler();
          setLoading(false);
        });
    }
  }, [loading, currentPDFiumState, pdfiumErrorHandler, setLoading, setLoadingMessage, setCurrentPDFiumState]);

  return (
    <DocumentDataManagerProvider>
      <ViewerPagesRefProvider>
        <Wrapper {...styles}>
          <ViewerHeader />
          <ViewerMenu pdfRef={pdfInstance} />
          <ViewerContent pdfRef={pdfInstance} />
          <Loading show={loading} target={loadingMessage} />
        </Wrapper>
      </ViewerPagesRefProvider>
    </DocumentDataManagerProvider>
  );
};

const defaultProps: ViewerProps = {
  width: "100%",
  height: "100%",
  background: "#f8fafc",
};

Viewer.defaultProps = defaultProps;
