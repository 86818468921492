/**
 * External modules
 */
import React from "react";
import styled from "styled-components";

/**
 * Internal modules
 */
import { Viewer } from "./components/viewer/Viewer";

const Wrapper = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <Wrapper>
      <Viewer />
    </Wrapper>
  );
}

export default App;
