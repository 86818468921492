/**
 * External modules
 */
import React, { useCallback, useEffect, useMemo } from "react";

/**
 * Internal modules
 */
import { debounce } from "../utils";

export const useResizeObserver = (targetRef: React.RefObject<HTMLElement>, callback: (rect: DOMRectReadOnly) => void) => {
  const handleResize = useCallback<ResizeObserverCallback>((entries) => {
    const rect = entries.at(0)?.contentRect;
    if (!rect) {
      console.log("[WARN] Resize observer can't get target element rect info");
      return;
    }
    debounce(callback, 1000)(rect);
  }, [callback]);

  const resizeObserver = useMemo(() => new ResizeObserver(handleResize), [handleResize]);

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }
    resizeObserver.observe(targetRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [targetRef, resizeObserver]);
};
