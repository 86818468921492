/**
 * External modules
 */
import { useContext } from "react";

/**
 * Internal modules
 */
import { ViewerPagesRefContext } from "../contexts/ViewerPagesRefContext";

export const useViewerPagesRef = () => useContext(ViewerPagesRefContext);
