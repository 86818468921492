/**
 * External modules
 */
import { atom } from "recoil";

/**
 * Type modules
 */
import type { LayoutType } from "../components/viewer/menu/MenuLayoutSwitch";

export const layoutState = atom<LayoutType>({
  key: "layout",
  default: "single",
});

export const gridSizeState = atom({
  key: "grid-size",
  default: 2,
});

/**
 * This state affects only single layout state
 */
export const currentPageState = atom({
  key: "current-page",
  default: 0,
});

export const showThumbnailsState = atom({
  key: "show-thumbnails",
  default: true,
});

export const contentViewerWidth = atom({
  key: "content-viewer-width",
  default: 0,
});

export const contentViewerHeight = atom({
  key: "content-viewer-height",
  default: 0,
});
