/**
 * External modules
 */
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import styled from "styled-components";

/**
 * Internal modules
 */
import { pageCountState } from "../../../states/document";
import { currentPageState } from "../../../states/layout";

interface NavigatorButtonProps {
  visible: boolean;
  left?: boolean;
  right?: boolean;
  onClick?: () => void;
}

const NavigatorWrapper = styled.div<NavigatorButtonProps>`
  height: 40px;
  padding: 0 8px;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 20;
  cursor: pointer;
  top: calc(50% - 20px);
  ${({ left }) => (left ? `left: 0;` : "")}
  ${({ right }) => (right ? `right: 0;` : "")}
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
`;

const NavigatorButton = (props: NavigatorButtonProps) => {
  return (
    <NavigatorWrapper
      className="document-navigator"
      visible={props.visible}
      left={props.left}
      right={props.right}
      onClick={props.onClick}
    >
      {props.left ? <BsChevronLeft color="white" size={24} /> : null}
      {props.right ? <BsChevronRight color="white" size={24} /> : null}
    </NavigatorWrapper>
  );
};

const DocumentNavigatorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const DocumentNavigator = () => {
  const pageCount = useRecoilValue(pageCountState);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const [navigatorVisible, setNavigatorVisibility] = useState(false);
  const [navigatorVisibleTimeoutId, setNavigatorVisibleTimeoutId] = useState<NodeJS.Timeout>();

  /**
   * Show page navigator when mouse enters this element.
   * If page navigator hidden timeout already fired, it will clear the timeout.
   */
  const showNavigator = useCallback(() => {
    if (navigatorVisibleTimeoutId) {
      clearTimeout(navigatorVisibleTimeoutId);
    }
    setNavigatorVisibility(true);
  }, [navigatorVisibleTimeoutId]);

  /**
   * Hide page navigator when mouse leaves this element.
   * Page navigator will be hidden with 1s delay.
   */
  const handleMouseLeave = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setNavigatorVisibility(false);
      setNavigatorVisibleTimeoutId(undefined);
    }, 1000);
    setNavigatorVisibleTimeoutId(timeoutId);
  }, []);

  const navigate = useCallback((direction: "left" | "right") => () => {
    if (direction === "left" && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    if (direction === "right" && currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, pageCount, setCurrentPage]);

  /**
   * Navigate page by keyboard arrow key event
   * When page navigate performed, then show navigator.
   */
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      navigate("right")();
      showNavigator();
    } else if (e.key === "ArrowLeft") {
      navigate("left")();
      showNavigator();
    }
  }, [navigate, showNavigator]);

  /**
   * Register / Unregister keyboard event listener
   */
  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    }
  }, [handleKeyPress]);

  return (
    <DocumentNavigatorWrapper onMouseEnter={showNavigator} onMouseLeave={handleMouseLeave}>
      <NavigatorButton left visible={navigatorVisible} onClick={navigate("left")} />
      <NavigatorButton right visible={navigatorVisible} onClick={navigate("right")} />
    </DocumentNavigatorWrapper>
  )
};
