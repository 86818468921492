/**
 * convert file object to byte array
 * @param {File} file target file
 * @returns {Promise<Uint16Array>} a promise object that resolves array contains byte as number
 */
export const convertFileToByteArray = (file: File) => {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      if (e.target?.readyState !== FileReader.DONE) {
        return;
      }
      const arrayBuffer = <ArrayBuffer>e.target.result;
      // buffer to byte array
      const byteArray = new Uint8Array(arrayBuffer);
      resolve(byteArray);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export const convertFileToString = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      if (e.target?.readyState !== FileReader.DONE) {
        return;
      }
      const result = e.target.result as string;
      resolve(result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export const getAspectRatio = (x: number, y: number) => {
  const result = { x, y };
  let tmp;
  if (x < y) {
    tmp = x;
    x = y;
    y = tmp;
  }

  while (y !== 0) {
    tmp = x % y;
    x = y;
    y = tmp;
  }

  result.x /= x;
  result.y /= x;
  return result;
};

export const debounce = <ParamType>(callback: (...params: ParamType[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...params: ParamType[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...params), delay);
  };
};

export const downloadJSON = (filename: string, jsonStr: string) => {
  const downloadAnchor = document.createElement("a");
  downloadAnchor.href = `data:text/json;charset=utf-8,${encodeURIComponent(jsonStr)}`;
  downloadAnchor.download = `${filename}.json`;
  document.body.appendChild(downloadAnchor);
  downloadAnchor.click();
  downloadAnchor.remove();
};
