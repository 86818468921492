/**
 * External modules
 */
import { atom } from "recoil";

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const loadingMessageState = atom({
  key: "loading-message",
  default: "",
});
