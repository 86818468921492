/**
 * External modules
 */
import { useSetRecoilState } from "recoil";

/**
 * Internal modules
 */
import { PDFiumState, PDFiumStateType } from "../states/pdfium";

export const usePDFiumError = () => () => {
  // TODO fix later
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const setPDFiumState = useSetRecoilState(PDFiumState);
  setPDFiumState(PDFiumStateType.ERROR);
  console.error("PDFium error occurred");
  // something to do
};
