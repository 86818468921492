/**
 * Internal modules
 */
import { FPDFBitmapFormat, MEMORY_UNSET, NUMBER_UNSET } from "../libs/pdfium-constants";
import * as wasmMemory from "./wasmMemory";
import { FPDFPageOrientation, FPDFPageRenderingFlag } from "../libs/pdfium-constants";

/**
 * JS Wrapper class to handle PDFium's C level instance.
 * This class provides PDF document API.
 *
 * PDFium Web Assembly module must be loaded before using this class.
 * PDFium module path: `window.FPDF`
 */
export class PDF {
  filename: string;
  fileSize: number;
  byteArray: Uint8Array;
  memory: {
    binaryAddress: number;
    documentAddress: number;
  };
  pageCount: number;
  opened: boolean;
  pages: PDFPage[] | null;

  /**
   * Create PDF file instance.
   * The initial value of memory address is -1 (which is not allocated address)
   * @param filename target file name
   * @param byteArray target file byte array
   */
  constructor(filename: string, byteArray: Uint8Array) {
    this.filename = filename;
    this.byteArray = byteArray;
    this.fileSize = byteArray.length;
    this.memory = {
      binaryAddress: MEMORY_UNSET,
      documentAddress: MEMORY_UNSET,
    };
    this.pageCount = NUMBER_UNSET;
    this.opened = false;
    this.pages = null;
  }

  /**
   * Check PDF file is opened.
   * If PDF opened, binaryAddress and documentAddress should not be -1.
   * @returns PDF opened state
   */
  isLoaded() {
    if (!this.opened || this.memory.binaryAddress < 0 || this.memory.documentAddress < 0) {
      return false;
    }
    return true;
  }

  /**
   * Open PDF page.
   * The open method automatically creates PDFPage instances of each page.
   */
  open() {
    if (this.isLoaded()) {
      console.warn("PDF document already loaded!");
      return;
    }

    this.memory.binaryAddress = window.FPDF.asm.malloc(this.fileSize);
    window.FPDF.HEAPU8.set(this.byteArray, this.memory.binaryAddress);
    this.memory.documentAddress = window.FPDF._FPDF_LoadMemDocument(this.memory.binaryAddress, this.fileSize, "");
    this.opened = true;
    this.pageCount = this.getPageCount();
    this.pages = Array(this.pageCount);
    for (let i = 0; i < this.pageCount; i++) {
      this.pages[i] = new PDFPage(this.memory.documentAddress, i);
    }
  }

  /**
   * Close PDF file instance.
   * It will automatically check the memory and free up dynamically allocated memory.
   */
  close() {
    if (!this.isLoaded()) {
      console.warn("PDF document was not loaded!");
      return;
    }

    window.FPDF._FPDF_CloseDocument(this.memory.documentAddress);
    window.FPDF.asm.free(this.memory.binaryAddress);
    this.pageCount = NUMBER_UNSET;
    this.memory.binaryAddress = MEMORY_UNSET;
    this.memory.documentAddress = MEMORY_UNSET;
    this.opened = false;
  }

  /**
   * Get PDF page count.
   * @returns PDF page count
   */
  getPageCount() {
    if (!this.isLoaded()) {
      console.warn("PDF document was not loaded!");
      return 0;
    }

    if (this.pageCount < 1) {
      this.pageCount = window.FPDF._FPDF_GetPageCount(this.memory.documentAddress);
    }

    return this.pageCount;
  }
}

/**
 * JS Wrapper class to handle PDFium's C level instance.
 * This class provides PDF page level API
 *
 * PDFium Web Assembly module must be loaded before using this class.
 * PDFium module path: `window.FPDF`
 */
export class PDFPage {
  pageIndex: number;
  memory: {
    documentAddress: number;
    pageAddress: number;
    bitmapAddress: number;
    bitmapBufferAddress: number;
    bitmapBufferSize: number;
  };
  size: {
    width: number;
    height: number;
  };
  loaded: boolean;

  /**
   * Create PDFPage instance.
   * The initial value of memory address is -1 (which is not allocated address)
   * @param documentAddress document address (FPDFDocument instance memory handle)
   * @param pageIndex target page index
   */
  constructor(documentAddress: number, pageIndex: number) {
    this.pageIndex = pageIndex;
    this.memory = {
      documentAddress,
      pageAddress: MEMORY_UNSET,
      bitmapAddress: MEMORY_UNSET,
      bitmapBufferAddress: MEMORY_UNSET,
      bitmapBufferSize: NUMBER_UNSET,
    };
    this.size = {
      width: NUMBER_UNSET,
      height: NUMBER_UNSET,
    };
    this.loaded = false;
  }

    /**
   * Check PDF page is opened.
   * If page opened, pageAddress and documentAddress should not be -1.
   * @returns PDF opened state
   */
  isLoaded() {
    if (!this.isLoaded || this.memory.documentAddress < 0 || this.memory.pageAddress < 0) {
      return false;
    }
    return true;
  }

  /**
   * Load page instance using PDFium API.
   * If page instsance created, it will measure page size.
   */
  load() {
    if (this.isLoaded()) {
      console.warn(`PDF Page index: ${this.pageIndex} already loaded!`);
      return;
    }

    this.memory.pageAddress = window.FPDF._FPDF_LoadPage(this.memory.documentAddress, this.pageIndex);
    // const result = window.FPDF._FPDF_GetPageSizeByIndex(
    //   this.memory.documentAddress,
    //   this.pageIndex,
    //   this.size.width,
    //   this.size.height
    // );
    // if (result === 0) {
    //   console.warn(`Failed to call GetPageSizeByIndex index: ${this.pageIndex} code: ${result}`);
    // } else {
    //   console.log("page size:", this.size);
    // }
    this.size.width = window.FPDF._FPDF_GetPageWidthF(this.memory.pageAddress);
    this.size.height = window.FPDF._FPDF_GetPageHeightF(this.memory.pageAddress);
    this.loaded = true;
  }

  /**
   * Close PDF page instance.
   * It will automatically check the memory and free up dynamically allocated memory.
   */
  close() {
    if (!this.isLoaded()) {
      console.warn(`PDF Page index: ${this.pageIndex} was not loaded!`);
      return;
    }

    // destroy bitmap buffer memory
    if (this.memory.bitmapBufferAddress) {
      window.FPDF.asm.free(this.memory.bitmapBufferAddress);
      this.memory.bitmapBufferAddress = MEMORY_UNSET;
      this.memory.bitmapBufferSize = NUMBER_UNSET;
    }
    // destroy bitmap instance
    if (this.memory.bitmapAddress) {
      window.FPDF._FPDFBitmap_Destroy(this.memory.bitmapAddress);
      this.memory.bitmapAddress = MEMORY_UNSET;
    }
    window.FPDF._FPDF_ClosePage(this.memory.pageAddress);
  }

  /**
   * Render page as bitmap format.
   */
  render(width = this.size.width, height = this.size.height) {
    // destroy bitmap if memory already allocated
    if (this.memory.bitmapAddress !== MEMORY_UNSET) {
      window.FPDF._FPDFBitmap_Destroy(this.memory.bitmapAddress);
      this.memory.bitmapAddress = MEMORY_UNSET;
    }
    if (this.memory.bitmapBufferAddress !== MEMORY_UNSET) {
      window.FPDF.asm.free(this.memory.bitmapBufferAddress);
      this.memory.bitmapBufferAddress = MEMORY_UNSET;
    }

    const STRIDE = 4;
    const renderFlag =
      FPDFPageRenderingFlag.FPDF_REVERSE_BYTE_ORDER |
      FPDFPageRenderingFlag.FPDF_LCD_TEXT |
      FPDFPageRenderingFlag.FPDF_ANNOT;
    this.memory.bitmapBufferSize = width * height * STRIDE;
    // bitmap buffer address
    this.memory.bitmapBufferAddress = window.FPDF.asm.malloc(this.memory.bitmapBufferSize);
    // fill buffer memory to 0
    wasmMemory.fillValue(window.FPDF.HEAPU8, this.memory.bitmapBufferAddress, this.memory.bitmapBufferSize, 0);

    // create bitmap and return bitmap handle memory address
    this.memory.bitmapAddress = window.FPDF._FPDFBitmap_CreateEx(
      width,
      height,
      FPDFBitmapFormat.FPDFBitmap_BGRA,
      this.memory.bitmapBufferAddress,
      width * STRIDE
    );
    window.FPDF._FPDFBitmap_FillRect(this.memory.bitmapAddress, 0, 0, width, height, 0xffffffff);
    window.FPDF._FPDF_RenderPageBitmap(
      this.memory.bitmapAddress,
      this.memory.pageAddress,
      0,
      0,
      width,
      height,
      FPDFPageOrientation.NORMAL,
      renderFlag
    );
  }
}
