/**
 * External modules
 */
import React from "react";
import styled from "styled-components";

const PenWidthPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  & > .width-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;

    /* width-* classes */
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 40px;
      height: 100%;
      cursor: pointer;
      transition-property: background;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;


      & > div {
        transition: inherit;
        background: #52525b;
      }

      &:hover {
        background: #d4d4d8;
      }
    }

    & > div.active {
      background: #d4d4d8;
      & > div {
        background: #27272a;
      }
    }
  }

  & .width-1 > div {
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }

  & .width-2 > div {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  & .width-3 > div {
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }

  & .width-4 > div {
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
`;

interface PenWidthPickerProps {
  onChange: (width: number) => void;
  value: number;
}

export const MenuPenWidthPicker = (props: PenWidthPickerProps) => {
  return (
    <PenWidthPickerWrapper>
      <div className="width-wrapper">
        <div className={`width-1 ${props.value === 4 ? "active" : ""}`} onClick={() => props.onChange(4)}>
          <div></div>
        </div>
        <div className={`width-2 ${props.value === 8 ? "active" : ""}`} onClick={() => props.onChange(8)}>
          <div></div>
        </div>
        <div className={`width-3 ${props.value === 16 ? "active" : ""}`} onClick={() => props.onChange(16)}>
          <div></div>
        </div>
        <div className={`width-4 ${props.value === 32 ? "active" : ""}`} onClick={() => props.onChange(32)}>
          <div></div>
        </div>
      </div>
    </PenWidthPickerWrapper>
  );
};
