/**
 * External modules
 */
import React, { useCallback } from "react";
import styled from "styled-components";

/**
 * Internal modules
 */
import { Button } from "../../common/Button";

const Wrapper = styled.div`
  display: flex;
`;

export type MenuEditChangeHandler = (changedEdit: boolean) => void;

interface Props {
  value: boolean;
  onChange: MenuEditChangeHandler;
}

export const MenuEditSwitch = (props: Props) => {
  const { value, onChange } = props;

  const handleSwitch = useCallback((edit: boolean) => () => {
    onChange(edit);
  }, [onChange]);

  return (
    <Wrapper >
      <Button
        color={!value ? "#2563eb" : "#737373"}
        hoverBackground="#d4d4d4"
        background="#e5e5e5"
        onClick={handleSwitch(false)}>
        View
      </Button>
      <Button
        color={value ? "#2563eb" : "#737373"}
        hoverBackground="#d4d4d4"
        background="#e5e5e5"
        onClick={handleSwitch(true)}>
        Edit
      </Button>
    </Wrapper>
  );
};
