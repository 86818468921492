/**
 * External modules
 */
import React from "react";
import styled from "styled-components";
import { compose, background, width } from "styled-system";

/**
 * Type modules
 */
import type { BackgroundProps, WidthProps } from "styled-system";

interface ViewerSideProps extends BackgroundProps, WidthProps {
  position: "left" | "right";
  show: boolean;
  overlay?: boolean;
}

const Wrapper = styled.aside<ViewerSideProps>`
  ${compose(background, width)}
  height: 100%;
  display: ${({ show }) => (show ? `flex` : `none`)};
  ${({ overlay }) => overlay ? `position: absolute;` : ''}
  top: 0;
  bottom: 0;
  ${({ position }) => (position === "left" ? `left: 0;` : `right: 0;`)};
`;

export const ViewerSide = (props: React.PropsWithChildren<ViewerSideProps>) => {
  const { children, overlay = false, ...styles } = props;
  return (
    <Wrapper overlay={overlay} {...styles}>
      {children}
    </Wrapper>
  );
};
