/**
 * External modules
 */
import styled from "styled-components";
import { compose, alignItems, justifyContent, background, color, flex, flexDirection, position, space } from "styled-system";

/**
 * Type modules
 */
import type {
  AlignItemsProps,
  BackgroundProps,
  ColorProps,
  FlexProps,
  FlexDirectionProps,
  JustifyContentProps,
  PositionProps,
  SpaceProps,
} from "styled-system";

interface ButtonProps
  extends BackgroundProps,
    ColorProps,
    FlexProps,
    FlexDirectionProps,
    AlignItemsProps,
    JustifyContentProps,
    PositionProps,
    SpaceProps {
  round?: boolean;
  hoverBackground?: string;
  hoverColor?: string;
  iconBottomMargin?: string | number;
}

export const Button = styled.button<ButtonProps>`
  ${compose(space, color, background, flex, flexDirection, alignItems, justifyContent, position)}
  display: flex;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.15s ease-out;
  ${({ round }) => (round ? "border-radius: 6px;" : "")}

  :hover:not([disabled]) {
    background-color: ${({ hoverBackground }) => hoverBackground ?? "none"};
    ${({ hoverColor }) => hoverColor ? `color: ${hoverColor};` : ""}
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  & > svg {
    margin-bottom: ${({ iconBottomMargin }) => iconBottomMargin ?? 0};
  }
`;

Button.defaultProps = {
  color: "#fff",
  py: "8px",
  px: "12px",
  hoverBackground: "#60a5fa",
  background: "#3b82f6",
  alignItems: "center",
  justifyContent: "center",
  round: false,
};
