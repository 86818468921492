/**
 * External modules
 */
import React, { createContext, useRef } from "react";

/**
 * Type modules
 */
import type { ViewerPagesRefs } from "../components/viewer/ViewerPages";

export const ViewerPagesRefContext = createContext<React.RefObject<ViewerPagesRefs> | null>(null);

export const ViewerPagesRefProvider = (props: React.PropsWithChildren) => {
  const ref = useRef<ViewerPagesRefs>(null);
  return (
    <ViewerPagesRefContext.Provider value={ref}>
      {props.children}
    </ViewerPagesRefContext.Provider>
  )
};
