/**
 * External modules
 */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

/**
 * Internal modules
 */
import { ViewerSide } from "./ViewerSide";
import { DocumentPage } from "./document/DocumentPage";
import { showThumbnailsState, currentPageState } from "../../states/layout";
import { pageCountState } from "../../states/document";
import { filenameState } from "../../states/pdfium";

/**
 * Type modules
 */
import type { WidthProps } from "styled-system";
import type { DocumentPageProps, DocumentPageRefs, ShouldPageRender } from "./document/DocumentPage";
import type { PDF } from ".././../modules/pdf";

const Wrapper = styled.div`
  display: flex;
  flex: 1 0;
  padding: 8px 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ThumbnailWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex: 1 1 100px;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;

  & > p {
    color: ${({ selected }) => (selected ? "#3b82f6" : "#6b7280")};
    font-weight: 500;
    margin-top: 4px;
    font-size: 14px;
    padding: 4px;
  }
`;

const Thumbnail = (props: Omit<DocumentPageProps, "showPageNumber">) => {
  const { pageIndex, containerRef, ...rest } = props;
  // refs
  const pageRef = useRef<DocumentPageRefs>(null);
  // states
  const currentPage = useRecoilValue(currentPageState);

  const updateRender = useCallback(() => {
    //  manipulate ref to directly request render
    pageRef.current?.update();
  }, []);

  const shouldPageRender = useCallback<ShouldPageRender>((pageState, pageWrapperRef) => {
    if (pageState === "RENDERING" || pageState === "RENDERED") {
      return false;
    }

    if (!pageWrapperRef.current || !containerRef.current) {
      return false;
    }

    const pageBounds = pageWrapperRef.current.getBoundingClientRect();
    const containerBounds = containerRef.current.getBoundingClientRect();

    // page is outside the top side of the container
    if (pageBounds.bottom < containerBounds.top - pageBounds.height) {
      return false;
    }
    // page is outside the bottom side of the container
    if (pageBounds.top > containerBounds.bottom + pageBounds.height) {
      return false;
    }

    return true;
  }, [containerRef]);

  /**
   * register / deregister scroll event listener
   */
  useEffect(() => {
    const containerEl = containerRef.current;
    containerEl?.addEventListener("scroll", updateRender);
    return () => {
      containerEl?.removeEventListener("scroll", updateRender);
    };
  }, [containerRef, updateRender]);

  return (
    <ThumbnailWrapper selected={currentPage === pageIndex}>
      <DocumentPage
        ref={pageRef}
        containerRef={containerRef}
        pageIndex={pageIndex}
        editable={false}
        showPageNumber={false}
        shouldPageRender={shouldPageRender}
        renderScale={2}
        border={currentPage === pageIndex ? "1px solid #3b82f6" : undefined}
        {...rest}
      />
      <p>{pageIndex + 1}</p>
    </ThumbnailWrapper>
  );
};

interface ViewerThumbnailsProps extends WidthProps {
  pdfRef: React.MutableRefObject<PDF | undefined>;
}

export const ViewerThumbnails = (props: ViewerThumbnailsProps) => {
  const { width = "200px", pdfRef } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  // states
  const showThumbnails = useRecoilValue(showThumbnailsState);
  const pageCount = useRecoilValue(pageCountState);
  const filename = useRecoilValue(filenameState);
  // thumbnail components
  const thumbnails = useMemo(() => {
    return Array.from({ length: pageCount }).map((_, i) => (
      <Thumbnail key={`${filename}-thumbnail-${i}`} pdfRef={pdfRef} containerRef={containerRef} pageIndex={i} />
    ));
  }, [filename, pageCount, pdfRef]);

  return (
    <ViewerSide width={width} position="left" show={showThumbnails} background="#e5e7eb">
      <Wrapper>
        <ContentWrapper ref={containerRef}>
          {thumbnails}
        </ContentWrapper>
      </Wrapper>
    </ViewerSide>
  );
};
