export const fillValue = (memory: Uint8Array, offset: number, size: number, fillValue: number) => {
  for (let i = 0; i < size; i++) {
    memory[offset + i] = fillValue;
  }
};

export const generateArrayFromMemory = (memory: Uint8Array, offset: number, size: number) => {
  const result = Array(size);
  for (let i = 0; i < size; i++) {
    result[i] = memory[offset + i];
  }
  return result;
};
