/**
 * External modules
 */
import React, { useCallback, useMemo, useState } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import styled from "styled-components";
import { AiOutlineFilePdf } from "react-icons/ai";
import { BsLayoutSidebarInset } from "react-icons/bs";
import { IoMdColorPalette } from "react-icons/io";
import { CirclePicker } from "react-color";

/**
 * Internal modules
 */
import { MenuLayoutSwitch } from "./menu/MenuLayoutSwitch";
import { MenuGridSizeSlider } from "./menu/MenuGridSizeSlider";
import { MenuFileSelectButton } from "./menu/MenuFileSelectButton";
import { MenuPenWidthPicker } from "./menu/MenuPenWidthPicker";
import { MenuEditSwitch } from "./menu/MenuEditSwitch";
import { MenuEditMode } from "./menu/MenuEditMode";
import { MenuShapeSelect } from "./menu/MenuShapeSelect";
import { Button, PopupMenu } from "../common";
import { useDocumentDataManager } from "../../hooks/useDocumentDataManager";
import { useViewerPagesRef } from "../../hooks/useViewerPagesRef";
import { DocumentDataManager } from "../../modules/data";
import { PDF } from "../../modules/pdf";
import * as PDFService from "../../services/pdfService";
import { pageCountState } from "../../states/document";
import { currentPageState, layoutState, showThumbnailsState } from "../../states/layout";
import { loadingState, loadingMessageState } from "../../states/loading";
import { filenameState, fileStatusState, FileStatusType } from "../../states/pdfium";
import {
  currentPenColorState,
  currentPenWidthState,
  currentEditModeState,
  currentShapeState,
  isEditState,
} from "../../states/editor";
import { convertFileToByteArray, convertFileToString, downloadJSON } from "../../utils";

/**
 * Type modules
 */
import type { ColorChangeHandler } from "react-color";
import type { MenuLayoutChangeHandler } from "./menu/MenuLayoutSwitch";
import type { MenuEditChangeHandler } from "./menu/MenuEditSwitch";
import type { MenuEditModeChangeHandler } from "./menu/MenuEditMode";
import type { MenuShapeSelectHandler } from "./menu/MenuShapeSelect";

const COLOR_PICKER_PRESET = [
  "#000000",
  "#333333",
  "#4D4D4D",
  "#666666",
  "#808080",
  "#B3B3B3",
  "#cccccc",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#68CCCA",
  "#FDA1FF",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#9F0500",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0062B1",
  "#653294",
  "#AB149E",
];

const Wrapper = styled.section`
  width: 100%;
  min-height: 52px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  background-color: #f4f4f5;
  padding: 4px;
`;

const PenColorPickerWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  z-index: 20;
  background: #fff;
  padding: 12px;
  box-shadow: 0px 2px 4px 2px #bebebe;
  border-radius: 4px;
`;

interface MenuProps {
  pdfRef: React.MutableRefObject<PDF | undefined>;
}

export const ViewerMenu = (props: MenuProps) => {
  const { pdfRef: pdfInstance } = props;
  // refs
  const viewerPagesRef = useViewerPagesRef();
  const documentDataManager = useDocumentDataManager();
  // states
  const [fileMenuOpem, setFileMenuOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [layout, setLayout] = useRecoilState(layoutState);
  const [filename, setFilename] = useRecoilState(filenameState);
  const [fileStatus, setFileStatus] = useRecoilState(fileStatusState);
  const [editMode, setEditMode] = useRecoilState(currentEditModeState);
  const [showThumbnails, setShowThumbnails] = useRecoilState(showThumbnailsState);
  const [currentPenColor, setCurrentPenColor] = useRecoilState(currentPenColorState);
  const [currentPenWidth, setCurrentPenWidth] = useRecoilState(currentPenWidthState);
  const [currentShape, setCurrentShape] = useRecoilState(currentShapeState);
  const [isEdit, setEdit] = useRecoilState(isEditState);
  // state setters
  const setLoading = useSetRecoilState(loadingState);
  const setLoadingMessage = useSetRecoilState(loadingMessageState);
  const setPageCount = useSetRecoilState(pageCountState);
  const setCurrentPage = useSetRecoilState(currentPageState);

  /**
   * File popup menu opener toggle handler
   */
  const handleFilePopupMenuClick = useCallback(() => {
    setFileMenuOpen(!fileMenuOpem);
  }, [fileMenuOpem]);

  /**
   * Close button handler
   */
  const handlePDFClose = useCallback(
    (force?: boolean) => {
      if (!force && !window.confirm("Do you want to close this document?")) {
        return;
      }

      PDFService.closePDF(pdfInstance.current);
      if (documentDataManager?.current) {
        documentDataManager.current.data = null;
      }
      setPageCount(0);
      setFileStatus(FileStatusType.NOT_SELECTED);
      setFilename("");
      setCurrentPage(0);
      setLayout("single");
    },
    [pdfInstance, documentDataManager, setCurrentPage, setFileStatus, setFilename, setPageCount, setLayout]
  );

  /**
   * Layout change handler
   */
  const handlePDFLayoutChange = useCallback<MenuLayoutChangeHandler>(
    (changedLayout) => {
      if (changedLayout === "multi" && isEdit) {
        alert("Cannot change to multi view mode while editing.");
        return;
      }

      setLayout(changedLayout);
    },
    [isEdit, setLayout]
  );

  /**
   * Edit mode change handler
   */
  const handleEditModeChange = useCallback<MenuEditModeChangeHandler>(
    (editMode) => {
      setEditMode(editMode);
    },
    [setEditMode]
  );

  /**
   * Thumbnails menu toggle handler
   */
  const handleThumbnailsMenuClick = useCallback(() => {
    setShowThumbnails(!showThumbnails);
  }, [setShowThumbnails, showThumbnails]);

  /**
   * Pen color picker toggle handler
   */
  const handlePenColorPickerClick = useCallback(() => {
    setShowColorPicker(!showColorPicker);
  }, [showColorPicker]);

  /**
   * Pen color picker change handler
   */
  const handlePenColorChange = useCallback<ColorChangeHandler>(
    (color) => {
      setCurrentPenColor(color.hex);
    },
    [setCurrentPenColor]
  );

  /**
   * Pen width picker change handler
   */
  const handlePenWidthChange = useCallback(
    (width: number) => {
      setCurrentPenWidth(width);
    },
    [setCurrentPenWidth]
  );

  /**
   * File popup menu item click handler
   */
  const handleFilePopupMenuItemClick = useCallback(
    (index: number) => {
      switch (index) {
        // download edit data
        case 3:
          if (viewerPagesRef?.current) {
            downloadJSON(`${filename}-edit-data`, viewerPagesRef.current.exportPagesEditData());
          }
          break;
        // close PDF
        case 4:
          handlePDFClose();
          break;
      }
      setFileMenuOpen(false);
    },
    [filename, viewerPagesRef, handlePDFClose]
  );

  /**
   * Edit mode switch handler
   */
  const handleEditToggle = useCallback<MenuEditChangeHandler>(
    (changedEdit) => {
      if (layout === "multi" && changedEdit) {
        alert("Cannot switch to edit mode in multi view mode.");
        return;
      }

      setEdit(changedEdit);
    },
    [layout, setEdit]
  );

  const handleShapeSelect = useCallback<MenuShapeSelectHandler>(
    (shape) => {
      setCurrentShape(shape);
    },
    [setCurrentShape]
  );

  /**
   * PDF File select handler
   */
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      // force close PDF file before load new PDF document
      if (fileStatus === FileStatusType.OPENED) {
        handlePDFClose(true);
      }

      const pdfFile = e.target.files?.item(0);

      if (!pdfFile) return;
      setLoading(true);
      setLoadingMessage("File Binary Data");

      try {
        setFilename(pdfFile.name ?? "");
        const byteArray = await convertFileToByteArray(pdfFile);

        setLoadingMessage("PDF Instance");
        console.log("Creating PDF instance");
        pdfInstance.current = new PDF(pdfFile.name, byteArray);
        setFileStatus(FileStatusType.NOT_LOADED);

        const pageCount = PDFService.openPDF(pdfInstance.current);
        setFileStatus(FileStatusType.OPENED);
        setPageCount(pageCount);
      } catch (err) {
        alert("Failed to load edit data.");
        console.log(err);
      } finally {
        // reset input value
        e.target.value = "";
        setLoading(false);
      }
    },
    [fileStatus, pdfInstance, handlePDFClose, setFileStatus, setFilename, setLoading, setLoadingMessage, setPageCount]
  );

  /**
   * AI data JSON File select handler
   */
  const handleAIFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      const aiFile = e.target.files?.item(0);

      if (!aiFile) return;
      setLoading(true);
      setLoadingMessage("AI File Data");

      try {
        const rawData = await convertFileToString(aiFile);
        const aiData = JSON.parse(rawData);
        if (!DocumentDataManager.validateAIData(aiData)) {
          alert("The AI data file is not valid!");
          return;
        }
        if (!DocumentDataManager.checkAIDataCompatibility(aiData, pdfInstance.current)) {
          alert("The AI data file is incompatible!");
          return;
        }
        documentDataManager?.current.setData(aiData);
        viewerPagesRef?.current?.loadPagesAIData();
      } catch (err) {
        alert("Failed to load AI data.");
        console.log(err);
      } finally {
        // reset input value
        e.target.value = "";
        setLoading(false);
      }
    },
    [pdfInstance, viewerPagesRef, documentDataManager, setLoading, setLoadingMessage]
  );

  /**
   * Edit data JSON File select handler
   */
  const handleEditFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      const editFile = e.target.files?.item(0);

      if (!editFile) return;
      setLoading(true);
      setLoadingMessage("Edit File Data");

      try {
        const rawData = await convertFileToString(editFile);
        const editData = JSON.parse(rawData);
        viewerPagesRef?.current?.importPagesEditData(editData);
      } catch (err) {
        alert("Failed to load edit data.");
        console.log(err);
      } finally {
        // reset input value
        e.target.value = "";
        setLoading(false);
      }
    },
    [viewerPagesRef, setLoading, setLoadingMessage]
  );

  /**
   * File popup menu items
   */
  const fileMenuItems = useMemo(() => {
    return [
      <MenuFileSelectButton key="pdf-opener" inputKey="pdf-opener" accept=".pdf" onChange={handleFileChange}>
        {fileStatus === FileStatusType.OPENED ? "Re-Select File" : "Open File"}
      </MenuFileSelectButton>,
      <MenuFileSelectButton
        key="ai-data-opener"
        inputKey="ai-data-opener"
        accept="application/json"
        onChange={handleAIFileChange}
      >
        Load AI Data
      </MenuFileSelectButton>,
      <MenuFileSelectButton
        key="edit-data-opener"
        inputKey="edit-data-opener"
        accept="application/json"
        onChange={handleEditFileChange}
      >
        Load Edit Data
      </MenuFileSelectButton>,
      "Download Edit Data",
      "Close Document",
    ];
  }, [fileStatus, handleFileChange, handleAIFileChange, handleEditFileChange]);

  return (
    <Wrapper>
      <Button onClick={handleThumbnailsMenuClick}>
        <BsLayoutSidebarInset size={24} />
      </Button>
      <PopupMenu
        width="200px"
        show={fileMenuOpem}
        opener={
          <Button position="relative" onClick={handleFilePopupMenuClick}>
            <AiOutlineFilePdf size={24} />
          </Button>
        }
        onMenuItemClick={handleFilePopupMenuItemClick}
        menu={fileMenuItems}
      />
      <MenuLayoutSwitch
        layout={layout}
        disabled={fileStatus !== FileStatusType.OPENED}
        onChange={handlePDFLayoutChange}
      />
      <MenuEditSwitch value={isEdit} onChange={handleEditToggle} />
      {isEdit ? (
        <>
          <MenuEditMode editMode={editMode} onChange={handleEditModeChange} />
          <MenuPenWidthPicker value={currentPenWidth} onChange={handlePenWidthChange} />
          <Button
            hoverBackground="none"
            background={currentPenColor}
            style={{ position: "relative" }}
            onClick={handlePenColorPickerClick}
          >
            <IoMdColorPalette size={24} />
            {showColorPicker ? (
              <PenColorPickerWrapper>
                <CirclePicker
                  color={currentPenColor}
                  colors={COLOR_PICKER_PRESET}
                  circleSize={22}
                  onChange={handlePenColorChange}
                />
              </PenColorPickerWrapper>
            ) : null}
          </Button>
          <MenuShapeSelect shape={currentShape} onSelect={handleShapeSelect} />
        </>
      ) : (
        <MenuGridSizeSlider />
      )}
    </Wrapper>
  );
};
