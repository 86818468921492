/**
 * External modules
 */
import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import styled, { css } from "styled-components";
import { compose, width, height, space, border } from "styled-system";
import Konva from "konva";
import { Circle, Label, Layer, Stage, Tag, Text } from "react-konva";

/**
 * Internal modules
 */
import { Loading } from "../../common";
import { useDocumentDataManager } from "../../../hooks/useDocumentDataManager";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { PDFPage } from "../../../modules/pdf";
import * as PDFService from "../../../services/pdfService";
import * as CanvasService from "../../../services/canvasService";
import {
  currentPenColorState,
  currentEditModeState,
  currentPenWidthState,
  currentShapeState,
} from "../../../states/editor";

/**
 * Type modules
 */
import type { WidthProps, HeightProps, SpaceProps, BorderProps } from "styled-system";
import type { PDF } from "../../../modules/pdf";

const Wrapper = styled.div<WidthProps & HeightProps & SpaceProps>`
  ${compose(width, height, space)}
  display: flex;
  flex-shrink: 0;
  overflow-x: hidden;
  position: relative;
`;

const BorderCanvas = styled.canvas.attrs({ width: 100, height: 70 })<BorderProps>`
  ${border}
  background: #fff;
`;

/**
 * Page indicator pseudo element
 *
 * This element shows when cursor is hover on DocumentPage canvas.
 * Only visible when the `showPageNumber` props is true.
 */
const PageIndicator = css<ContentWrapperProps>`
  &::before {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    position: absolute;
    top: 16px;
    left: 16px;
    min-width: 36px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 8px;
    color: #0f172a;
    font-size: 18px;
    text-align: center;
    content: "${({ pageIndex }) => pageIndex + 1 ?? "-"}";
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`;

interface ContentWrapperProps extends WidthProps, HeightProps {
  pageIndex: number;
  showPageNumber: boolean;
}

const ContentWrapper = styled.div.attrs<ContentWrapperProps>(({ width, height }) => ({
  style: { width, height },
}))<ContentWrapperProps>`
  ${({ showPageNumber }) => (showPageNumber ? PageIndicator : "")}

  /* Konva stage container css */
  & > .edit-container {
    display: flex;
    position: absolute;
    inset: 0;
  }
`;

type PageState = "PREPARE" | "READY" | "RENDERING" | "RENDERED" | "ERROR";
type EditState = "EDITING" | "NONE";

export type ShouldPageRender = (pageState: PageState, pageWrapperRef: React.RefObject<HTMLElement>) => boolean;

export interface DocumentPageRefs {
  renderPage: () => void;
  update: () => void;
  exportEditData: () => string;
  importEditData: (data: string) => void;
  loadAIData: () => void;
}

export interface DocumentPageProps extends ContentWrapperProps, SpaceProps, BorderProps {
  pdfRef: React.MutableRefObject<PDF | undefined>;
  containerRef: React.RefObject<HTMLElement>;
  editable?: boolean;
  showLink?: boolean;
  shouldPageRender?: ShouldPageRender;
  renderScale?: number;
  onLinkClick?: (drawingNo: string) => void;
}

export const DocumentPage = forwardRef<DocumentPageRefs, DocumentPageProps>((props, ref) => {
  const {
    pageIndex,
    pdfRef,
    containerRef,
    width,
    height,
    renderScale,
    shouldPageRender,
    onLinkClick,
    editable = false,
    showLink = false,
    showPageNumber = true,
    ...styles
  } = props;
  // refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const editLayerRef = useRef<Konva.Layer>();
  const linkLayerRef = useRef<Konva.Layer>();
  const pointerRef = useRef<Konva.Circle>(null);
  const tooltipRef = useRef<Konva.Label>(null);
  const tooltipTextRef = useRef<Konva.Text>(null);
  const lastDrawLine = useRef<Konva.Line>();
  const pageRef = useRef<PDFPage>();
  const documentDataManager = useDocumentDataManager();
  // refs state
  const [linkRefs, setLinkRefs] = useState<React.MutableRefObject<Konva.Circle>[]>([]);
  // states
  const currentPenColor = useRecoilValue(currentPenColorState);
  const currentEditMode = useRecoilValue(currentEditModeState);
  const currentPenWidth = useRecoilValue(currentPenWidthState);
  const currentShape = useRecoilValue(currentShapeState);
  const [pageState, setPageState] = useState<PageState>("PREPARE");
  const [editState, setEditState] = useState<EditState>("NONE");
  const [hoverVisibility, setHoverVisibility] = useState(false);

  /**
   * Update hover pointer visibility when entering / leaving konva canvas
   */
  const updateHoverVisibility = useCallback(
    (visible: boolean) => () => {
      if (currentEditMode === "pen" || currentEditMode === "eraser") {
        setHoverVisibility(visible);
      } else {
        setHoverVisibility(false);
      }
    },
    [currentEditMode]
  );

  const handleEditFinish = useCallback(
    (e: KeyboardEvent, originalEvent: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (!originalEvent.target || !e.target) {
        return;
      }

      (originalEvent.target as Konva.Text).text((e.target as HTMLTextAreaElement).value);
      document.body.removeChild(e.target as Node);
      originalEvent.target.show();
    },
    []
  );

  /**
   * Konva double click / tap event handler.
   *
   * Activaate text edit if selected shape is text.
   */
  const handleKonvaDoubleClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (!editable) {
        return;
      }

      const shapeName = e.target.name();
      if (shapeName === "EditableText") {
        console.log("Double click event");
        const textEditEl = CanvasService.createTextEdit(e.target as Konva.Text);
        if (!textEditEl) {
          return;
        }

        e.target.hide();
        document.body.append(textEditEl);
        textEditEl.addEventListener("keypress", (keyEvent) => {
          if (keyEvent.key === "Enter") {
            handleEditFinish(keyEvent, e);
          }
        });
        textEditEl.focus();
      }
    },
    [editable, handleEditFinish]
  );

  /**
   * Start editing konva canvas
   */
  const handleKonvaEditStart = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (!editable || !editLayerRef.current) {
        return;
      }

      const pos = e.target.getStage()?.getPointerPosition();
      if (!pos) {
        console.log("[WARN] Cannot get pointer position for drawing");
        return;
      }

      if (currentEditMode === "eraser") {
        // find existing shape in clicked position
        editLayerRef.current.children?.forEach((shape) => {
          const shapeBound = shape.getClientRect();
          const pointerBound = pointerRef.current?.getClientRect();
          if (pointerBound && CanvasService.haveIntersection(shapeBound, pointerBound)) {
            shape.destroy();
          }
        });
      } else {
        // find existing shape in clicked position
        const intersection = editLayerRef.current.children?.some((shape) => {
          const shapeBound = shape.getClientRect();
          const pointerBound = pointerRef.current?.getClientRect();
          if (pointerBound && CanvasService.haveIntersection(shapeBound, pointerBound)) {
            return true;
          }
        });

        if (intersection) {
          return;
        }
      }

      setEditState("EDITING");

      if (currentEditMode === "pen") {
        lastDrawLine.current = CanvasService.createLine(pos, currentPenWidth, currentPenColor);
        editLayerRef.current.add(lastDrawLine.current);
      } else if (currentEditMode === "text") {
        const text = CanvasService.createEditableText(pos, currentPenColor);
        // const textTransformer = CanvasService.createTransformer(text);
        text.on("dblclick dbltap", handleKonvaDoubleClick);
        editLayerRef.current.add(text);
        // editLayerRef.current.add(textTransformer);
      } else if (currentEditMode === "shape") {
        switch (currentShape) {
          case "line":
            break;
          case "rect":
            break;
        }
      }
    },
    [editable, currentEditMode, currentPenColor, currentPenWidth, currentShape, handleKonvaDoubleClick]
  );

  /**
   * Show hover pointer overlay and draw line continuously
   */
  const handleKonvaEditMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (!editable) {
        return;
      }

      if (e.type === "mousemove" && pointerRef.current) {
        const pos = e.target.getStage()?.getPointerPosition();
        if (!pos) {
          return;
        }

        pointerRef.current.setPosition(pos);
      }

      if (!editLayerRef.current || !lastDrawLine.current) {
        return;
      }

      if (editState === "NONE") {
        return;
      }

      // prevent scrolling on touch device
      e.evt.preventDefault();
      const pos = e.target.getStage()?.getPointerPosition();
      if (!pos) {
        console.log("[WARN] Cannot get pointer position for drawing");
        return;
      }

      if (currentEditMode === "pen") {
        lastDrawLine.current.points([...lastDrawLine.current.points(), pos.x, pos.y]);
      } else if (currentEditMode === "eraser") {
        // find existing shape in clicked position
        editLayerRef.current.children?.forEach((shape) => {
          const shapeBound = shape.getClientRect();
          const pointerBound = pointerRef.current?.getClientRect();
          if (pointerBound && CanvasService.haveIntersection(shapeBound, pointerBound)) {
            shape.destroy();
          }
        });
      }
    },
    [currentEditMode, editable, editState]
  );

  /**
   * Finish konva canvas editing
   */
  const handleKonvaEditEnd = useCallback(() => {
    setEditState("NONE");
  }, []);

  /**
   * Konva link enter event handler.
   *
   * Change konva cursor style to pointer and show tooltip.
   */
  const handleKonvaLinkEnter = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (editable) {
        return;
      }

      const stageContainer = e.target.getStage()?.container();
      if (!stageContainer) {
        return;
      }

      stageContainer.style.cursor = "pointer";

      if (!tooltipRef.current) {
        return;
      }
      // show tooltip
      const { x, y } = e.target.getPosition();
      const radius = (e.target as Konva.Circle).radius();
      const tooltipX = x;
      const tooltipY = y - radius - 4;
      tooltipRef.current.position({ x: tooltipX, y: tooltipY });
      tooltipTextRef.current?.text(e.target.getAttr("data-link-to"));
      tooltipRef.current.show();
    },
    [editable]
  );

  /**
   * Konva link leave event handler.
   *
   * Change konva cursor style to default and hide tooltip.
   */
  const handleKonvaLinkLeave = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const stageContainer = e.target.getStage()?.container();
    if (!stageContainer) {
      return;
    }

    tooltipRef.current?.hide();
    stageContainer.style.cursor = "default";
  }, []);

  /**
   * Konva link click event handler.
   *
   * It calls `onLinkClick` callback handler.
   */
  const handleKonvaLinkClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (editable) {
        return;
      }
      onLinkClick?.(e.target.getAttr("data-link-to"));
    },
    [onLinkClick, editable]
  );

  /**
   * Export edit layer data to json string.
   *
   * This function is exported through ref object.
   */
  const exportEditData = useCallback(() => {
    if (!editLayerRef.current) {
      return "";
    }

    return editLayerRef.current.toJSON();
  }, []);

  /**
   * Import edit data from user selected file.
   * First, It destroy the current edit layer and create new edit layer from data json.
   * Then, add newly created edit layer to stage and adjust the order of layer.
   *
   * This function is exported through ref object.
   */
  const importEditData = useCallback((data: string) => {
    if (!editLayerRef.current) {
      return;
    }
    const loadedEditLayer = Konva.Node.create(data);

    // remove current edit layer
    editLayerRef.current.destroy();
    editLayerRef.current = loadedEditLayer as Konva.Layer;

    stageRef.current?.add(editLayerRef.current);
  }, []);

  /**
   * Load AI data from DocumentDataManager.
   *
   * This function is exported through ref object.
   */
  const loadAIData = useCallback(() => {
    if (!stageRef.current || !documentDataManager?.current.data) {
      return;
    }

    // remove current link layer if exists
    if (linkLayerRef.current) {
      linkLayerRef.current.destroy();
    }

    linkLayerRef.current = new Konva.Layer();
    linkLayerRef.current.visible(showLink);

    const drawingData = documentDataManager.current.data.drawings[pageIndex];
    const links = drawingData.objects
      .filter((object) => object.type === "drawing_no")
      .map((link) => {
        const linkPos = CanvasService.convertRelativePosition(link, drawingData.size, {
          width: stageRef.current?.width() ?? 0,
          height: stageRef.current?.height() ?? 0,
        });
        const linkRect = CanvasService.generateLink(linkPos, link.data);
        linkRect.on("mouseenter", handleKonvaLinkEnter);
        linkRect.on("mouseleave", handleKonvaLinkLeave);
        linkRect.on("click", handleKonvaLinkClick);
        return linkRect;
      });
    setLinkRefs((linkRefs) => {
      return Array.from({ length: links.length }).map((_, i) => {
        const ref = linkRefs[i] ?? createRef();
        ref.current = links[i];
        return ref;
      });
    });

    linkLayerRef.current.add(...links);

    stageRef.current?.add(linkLayerRef.current);
    linkLayerRef.current.moveToBottom();
  }, [showLink, documentDataManager, pageIndex, handleKonvaLinkEnter, handleKonvaLinkLeave, handleKonvaLinkClick]);

  /**
   * Create PDF page instance and open page to prepare render process.
   * If page instance successfully created, then change pageState to `READY`.
   */
  const initPageInstance = useCallback(() => {
    if (pageState !== "PREPARE" || pageRef.current || !pdfRef.current) {
      return;
    }

    pageRef.current = PDFService.openPDFPage(pageIndex, pdfRef.current);
    setPageState("READY");
  }, [pageState, pageIndex, pageRef, pdfRef]);

  /**
   * Determine render execution by component position in viewport.
   * If component is located outside of viewport, it does not perform render.
   * When component is enter the viewport, then it will return true
   */
  const _shouldPageRender = useCallback(() => {
    // if `shouldPageRender` provided, then call provided `shouldPageRender` and return the result.
    // if not provided, then perform default logic of `shouldPageRender`.
    if (shouldPageRender) {
      return shouldPageRender(pageState, pageWrapperRef);
    }

    if (pageState === "RENDERING" || pageState === "RENDERED") {
      return false;
    }
    if (!containerRef.current || !pageWrapperRef.current) {
      return false;
    }

    return true;
  }, [pageState, pageWrapperRef, containerRef, shouldPageRender]);

  /**
   * Render page's content as bitmap.
   * The result of rendering is displayed into canvas.
   * By using `setTimeout`, component could avoid the interaction blocking.
   *
   * This function is exported through ref object.
   */
  const renderPage = useCallback(() => {
    if (pageState === "RENDERING") {
      return;
    }

    if (!pageWrapperRef.current?.clientWidth || !pageWrapperRef.current.clientHeight) {
      return;
    }

    setPageState("RENDERING");
    initPageInstance();
    setTimeout(() => {
      console.log(`Rendering page ${pageIndex}`);
      PDFService.renderPage(pageRef, canvasRef, pageWrapperRef, renderScale);
      setPageState("RENDERED");
    }, 0);
  }, [pageWrapperRef, canvasRef, pageIndex, pageState, renderScale, initPageInstance]);

  /**
   * Check if page is needed to be updated and call page render.
   * If `_shouldPageRender` returns false, then just ignore the update request.
   *
   * This function is exported through ref object.
   */
  const update = useCallback(() => {
    if (!_shouldPageRender()) {
      return;
    }

    // console.log("[INFO] DocumentPage update called");
    renderPage();
    loadAIData();
  }, [_shouldPageRender, renderPage, loadAIData]);

  /**
   * Page width & height change event handler
   */
  const pageSizeChangeHandler = useCallback(() => {
    if (!containerRef.current || !canvasRef.current) {
      return;
    }

    // console.log("[INFO] DocumentPage resize detected. Re-render page content..");
    update();
  }, [containerRef, update]);

  useResizeObserver(pageWrapperRef, pageSizeChangeHandler);

  /**
   * Export methods as ref object
   */
  useImperativeHandle(ref, () => {
    return {
      renderPage,
      update,
      exportEditData,
      importEditData,
      loadAIData,
    };
  });

  /**
   * Perform first render
   */
  useEffect(() => {
    if (!containerRef.current || !canvasRef.current) {
      return;
    }

    update();
  }, [containerRef, canvasRef, pageState, update]);

  /**
   * Update callback functions for Konva links when onLinkClick and editable props value changed.
   */
  useEffect(() => {
    linkRefs.forEach((linkRef) => {
      linkRef.current.off("mouseenter");
      linkRef.current.off("click");
      linkRef.current.on("mouseenter", handleKonvaLinkEnter);
      linkRef.current.on("click", handleKonvaLinkClick);
    });
    stageRef.current?.find(".EditableText").forEach((editableText) => {
      editableText.off("dblclick dbltap");
      editableText.on("dblclick dbltap", handleKonvaDoubleClick);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleKonvaLinkClick, handleKonvaLinkEnter, handleKonvaDoubleClick]);

  /**
   * Add edit layer when stage loaded.
   * The reason why add edit layer dynamically is the possibilities of adding edit layer from data.
   * If edit layer is mounted as JSX element, user cannot change the whole instance of edit layer.
   */
  useEffect(() => {
    if (!stageRef.current) {
      return;
    }

    const editLayer = new Konva.Layer();
    editLayerRef.current = editLayer;
    stageRef.current.add(editLayer);
    editLayer.moveToBottom();
  }, []);

  return (
    <Wrapper ref={pageWrapperRef} width={width} height={height} {...styles}>
      <ContentWrapper width={width} height={height} pageIndex={pageIndex} showPageNumber={showPageNumber}>
        <BorderCanvas ref={canvasRef} {...styles} />
        <Stage
          ref={stageRef}
          width={canvasRef.current?.width}
          height={canvasRef.current?.height}
          className="edit-container"
          onMouseDown={handleKonvaEditStart}
          onTouchStart={handleKonvaEditStart}
          onMouseMove={handleKonvaEditMove}
          onTouchMove={handleKonvaEditMove}
          onMouseUp={handleKonvaEditEnd}
          onTouchEnd={handleKonvaEditEnd}
          onMouseEnter={updateHoverVisibility(true)}
          onMouseLeave={updateHoverVisibility(false)}
        >
          <Layer>
            <Circle
              ref={pointerRef}
              visible={hoverVisibility}
              opacity={0.8}
              x={-50}
              y={-50}
              fill={currentEditMode === "eraser" ? "#e4e4e7" : currentPenColor}
              radius={currentPenWidth / 2}
            />
            <Label ref={tooltipRef} opacity={0.8} visible={false}>
              <Tag
                fill="black"
                pointerDirection="down"
                pointerWidth={12}
                pointerHeight={8}
                lineJoin="round"
                cornerRadius={4}
              />
              <Text ref={tooltipTextRef} fill="white" fontSize={14} padding={6} />
            </Label>
          </Layer>
        </Stage>
      </ContentWrapper>
      <Loading show={pageState === "PREPARE"} showLabel={false} />
    </Wrapper>
  );
});

DocumentPage.displayName = "DocumentPage";
DocumentPage.defaultProps = {
  border: "1px solid #94a3b8",
};
