/**
 * External modules
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ImSpinner3 } from "react-icons/im";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div<{ show: boolean; hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ hidden }) => (!hidden ? "flex" : "none")};
  flex: 1;
  align-items: center;
  justify-content: center;
  transition-property: visibility, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;

  > span {
    margin-left: 8px;
    font-size: 20px;
    color: #3e3e3e;
  }

  > svg {
    animation: ${spin} 0.5s infinite linear;
  }
`;

interface Props {
  show?: boolean;
  target?: string;
  showLabel?: boolean;
}

export const Loading = (props: Props) => {
  const { show = false, showLabel = true, target = "" } = props;
  // refs
  const ref = useRef<HTMLDivElement>(null);
  // states
  const [hidden, setHide] = useState(true);

  /**
   * transition end handler to hide loading component
   */
  const handleTransitionEnd = useCallback(() => {
    setHide(!hidden);
  }, [hidden]);

  /**
   * register / deregister transitionend event
   */
  useEffect(() => {
    const self = ref.current;
    self?.addEventListener("transitionend", handleTransitionEnd);
    return () => {
      self?.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [handleTransitionEnd]);

  /**
   * to show Loading component immediately, need `hidden` state to set to false when `show` props set to true
   */
  useEffect(() => {
    if (show) {
      setHide(false);
    }
  }, [show]);

  return (
    <Wrapper ref={ref} show={show} hidden={hidden}>
      <ImSpinner3 size={24} />
      {showLabel ? <span>Loading...{target ? ` (${target})` : ""}</span> : null}
    </Wrapper>
  );
};
