/**
 * External modules
 */
import { atom } from "recoil";

export enum PDFiumStateType {
  NOT_LOADED = "NOT_LOADED",
  NOT_INITIALIZED = "NOT_INITIALIZED",
  READY = "READY",
  ERROR = "ERROR",
}

export const PDFiumState = atom({
  key: "pdfium-lib",
  default: PDFiumStateType.NOT_LOADED,
});

export const filenameState = atom({
  key: "filename",
  default: "",
});

export enum FileStatusType {
  NOT_SELECTED = "NOT_SELECTED",
  NOT_LOADED = "NOT_LOADED",
  OPENED = "OPENED",
  CLOSED = "CLOSED",
}

export const fileStatusState = atom({
  key: "file",
  default: FileStatusType.NOT_SELECTED,
});
