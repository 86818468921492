/**
 * External modules
 */
import React, { useCallback, useEffect, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

/**
 * Internal modules
 */
import { ViewerThumbnails } from "./ViewerThumbnails";
import { DocumentNavigator } from "./document/DocumentNavigator";
import { ViewerPages } from "./ViewerPages";
import { useViewerPagesRef } from "../../hooks/useViewerPagesRef";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { pageCountState } from "../../states/document";
import { layoutState, contentViewerWidth, contentViewerHeight } from "../../states/layout";

/**
 * Type modules
 */
import type { PDF } from "../../modules/pdf";

const Wrapper = styled.section`
  display: flex;
  flex: 1 0 0;
  position: relative;
  overflow-y: scroll;

  & > p {
    text-align: center;
    margin: auto;
  }
`;

const DocumentNavigatorWrapper = styled.div<{ show: boolean }>`
  display: ${({ show }) => show ? "flex" : "none"};
  flex: 1;
  position: relative;
  padding: 8px;
  overflow-x: hidden;
`;

interface ViewerContentProps {
  pdfRef: React.MutableRefObject<PDF | undefined>;
}

export const ViewerContent = (props: ViewerContentProps) => {
  const { pdfRef } = props;
  // refs
  const containerRef = useRef<HTMLDivElement>(null);
  const viewerPagesRef = useViewerPagesRef();
  // states
  const pageCount = useRecoilValue(pageCountState);
  const layout = useRecoilValue(layoutState);
  // state setters
  const setContentViewerWidth = useSetRecoilState(contentViewerWidth);
  const setContentViewerHeight = useSetRecoilState(contentViewerHeight);

  /**
   * content viewer size update handler
   * content viewer size is used to render the page to appropriate size
   */
  const updateContentViewerSize = useCallback((rect: DOMRectReadOnly) => {
    setContentViewerWidth(rect.width);
    setContentViewerHeight(rect.height);
  }, [setContentViewerHeight, setContentViewerWidth]);

  useResizeObserver(containerRef, updateContentViewerSize);

  /**
   * Set initial size of content viewer
   */
  useEffect(() => {
    if (containerRef.current) {
      setContentViewerWidth(containerRef.current.clientWidth);
      setContentViewerHeight(containerRef.current.clientHeight);
    }
  }, [setContentViewerHeight, setContentViewerWidth]);

  return (
    <Wrapper>
      <ViewerThumbnails pdfRef={pdfRef} />
      {pageCount === 0 ? <p>Click <b><i>Menu &gt; File &gt; Open File</i></b> to open a Document.</p> : null}
      <DocumentNavigatorWrapper ref={containerRef} show={pageCount > 0}>
        <ViewerPages ref={viewerPagesRef} pdfRef={pdfRef} />
        {layout === "single" ? <DocumentNavigator /> : null}
      </DocumentNavigatorWrapper>
    </Wrapper>
  );
};
