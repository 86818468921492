/**
 * External modules
 */
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";

/**
 * Internal modules
 */
import { DocumentPage } from "./document/DocumentPage";
import { useDocumentDataManager } from "../../hooks/useDocumentDataManager";
import {
  currentPageState,
  gridSizeState,
  layoutState,
  contentViewerWidth,
  contentViewerHeight,
} from "../../states/layout";
import { pageCountState } from "../../states/document";
import { filenameState } from "../../states/pdfium";
import { isEditState } from "../../states/editor";

/**
 * Type modules
 */
import type { LayoutType } from "./menu/MenuLayoutSwitch";
import type { DocumentPageProps, DocumentPageRefs, ShouldPageRender } from "./document/DocumentPage";
import type { PDF } from "../../modules/pdf";

interface ContentWrapperProps {
  layout: LayoutType;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: ${({ layout }) => (layout === "single" ? 0 : 1)};
  flex-wrap: ${({ layout }) => (layout === "multi" ? `wrap` : "nowrap")};
  gap: 4px 8px;
  overflow-x: hidden;
  align-content: flex-start;
  z-index: 10;
`;

interface PageRefs {
  exportEditData: () => string;
  importEditData: (data: string) => void;
  loadAIData: () => void;
}

const Page = forwardRef<PageRefs, DocumentPageProps>((props, ref) => {
  const { containerRef, width, height, pageIndex, ...rest } = props;
  // refs
  const pageRef = useRef<DocumentPageRefs>(null);
  // states
  const currentPage = useRecoilValue(currentPageState);

  const requestRender = useCallback(() => {
    //  manipulate ref to directly request render
    pageRef.current?.renderPage();
  }, []);

  const updateRender = useCallback(() => {
    pageRef.current?.update();
  }, []);

  const shouldPageRender = useCallback<ShouldPageRender>(
    (pageState, pageWrapperRef) => {
      if (pageState === "RENDERING" || pageState === "RENDERED") {
        return false;
      }

      if (!pageWrapperRef.current || !containerRef.current) {
        return false;
      }

      const offsetLeft = (containerRef.current.children[currentPage] as HTMLDivElement)?.offsetLeft;
      const pageBounds = pageWrapperRef.current.getBoundingClientRect();
      const containerBounds = containerRef.current?.getBoundingClientRect();

      // page is outside the right side of the container
      if (pageBounds.left > containerBounds.left + offsetLeft + pageBounds.width * 2) {
        // console.log("page " + pageIndex + " is right outside");
        return false;
      }
      // page is outside the left side of the container
      if (pageBounds.right < containerBounds.left + offsetLeft - pageBounds.width) {
        // console.log("page " + pageIndex + " is left outside");
        return false;
      }
      // page is outside the top side of the container
      if (pageBounds.bottom < containerBounds.top - pageBounds.height) {
        // console.log("page " + pageIndex + " is top outside");
        return false;
      }
      // page is outside the bottom side of the container
      if (pageBounds.top > containerBounds.bottom + pageBounds.height) {
        // console.log("page " + pageIndex + " is bottom outside");
        return false;
      }

      return true;
    },
    [containerRef, currentPage]
  );

  const exportEditData = useCallback(() => {
    if (!pageRef.current) {
      return "";
    }

    return pageRef.current.exportEditData();
  }, []);

  const importEditData = useCallback((data: string) => {
    if (!pageRef.current) {
      return;
    }

    pageRef.current.importEditData(data);
  }, []);

  const loadAIData = useCallback(() => {
    if (!pageRef.current) {
      return;
    }

    pageRef.current.loadAIData();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      exportEditData,
      importEditData,
      loadAIData,
    };
  });

  /**
   * call render method explicitly when current page is component pagee
   */
  useEffect(() => {
    if (currentPage === pageIndex) {
      requestRender();
    }
  }, [currentPage, pageIndex, requestRender]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const containerEl = containerRef.current;
    containerEl.addEventListener("scroll", updateRender);
    return () => {
      containerEl.removeEventListener("scroll", updateRender);
    };
  }, [containerRef, updateRender]);

  return (
    <DocumentPage
      ref={pageRef}
      width={width}
      height={height}
      pageIndex={pageIndex}
      containerRef={containerRef}
      showLink
      shouldPageRender={shouldPageRender}
      {...rest}
    />
  );
});

Page.displayName = "Page";

export interface ViewerPagesRefs {
  exportPagesEditData: () => string;
  importPagesEditData: (editData: string[]) => void;
  loadPagesAIData: () => void;
}

interface ViewerPagesProps {
  pdfRef: React.MutableRefObject<PDF | undefined>;
}

export const ViewerPages = forwardRef<ViewerPagesRefs, ViewerPagesProps>((props, ref) => {
  const { pdfRef } = props;
  // refs
  const containerRef = useRef<HTMLDivElement>(null);
  const documentDataManager = useDocumentDataManager();
  // states
  const [pageRefs, setPageRefs] = useState<React.RefObject<PageRefs>[]>([]);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const pageCount = useRecoilValue(pageCountState);
  const filename = useRecoilValue(filenameState);
  const gridSize = useRecoilValue(gridSizeState);
  const layout = useRecoilValue(layoutState);
  const viewerWidth = useRecoilValue(contentViewerWidth);
  const viewerHeight = useRecoilValue(contentViewerHeight);
  const isEdit = useRecoilValue(isEditState);

  /**
   * Konva link click event listener.
   *
   * When link clicked, find the link page using drawingNo
   * and change the current page to the link page.
   */
  const handleLinkClick = useCallback(
    (drawingNo: string) => {
      console.log("link clicked. drawing no:", drawingNo);
      const pageIndex = documentDataManager?.current.getPageIndexByDrawingNo(drawingNo);
      console.log("pageIndex:", pageIndex);
      if (typeof pageIndex === "undefined") {
        return;
      }
      setCurrentPage(pageIndex - 1);
    },
    [documentDataManager, setCurrentPage]
  );

  /**
   * Collect all pages edit data.
   *
   * This function is exported through ref object.
   */
  const exportPagesEditData = useCallback(() => {
    const data = pageRefs.map((pageRef) => pageRef.current?.exportEditData() ?? "");
    return JSON.stringify(data);
  }, [pageRefs]);

  /**
   * Import edit data to pages
   *
   * This function is exported through ref object.
   */
  const importPagesEditData = useCallback(
    (editData: string[]) => {
      if (pageCount !== editData.length) {
        alert("The document is incompatible with imported edit data.");
        return;
      }
      editData.forEach((data, i) => pageRefs[i].current?.importEditData(data));
    },
    [pageCount, pageRefs]
  );

  /**
   * Load ai data to pages
   *
   * This function is exported through ref object.
   */
  const loadPagesAIData = useCallback(() => {
    pageRefs.forEach((pageRef) => {
      pageRef.current?.loadAIData();
    });
  }, [pageRefs]);

  // memoized width by layout value
  const width = useMemo(() => {
    if (layout === "single") {
      return viewerWidth + "px";
    } else {
      return `calc(${viewerWidth}px / ${gridSize} - ${(gridSize - 1) * 8}px)`;
    }
  }, [layout, gridSize, viewerWidth]);
  // memoized height by layout value
  const height = useMemo(() => {
    return layout === "single" ? viewerHeight + "px" : "fit-content";
  }, [layout, viewerHeight]);
  // memoized page components
  const pages = useMemo(() => {
    setPageRefs((pageRefs) => {
      return Array.from({ length: pageCount }).map((_, i) => pageRefs[i] ?? createRef());
    });
    return Array.from({ length: pageCount }).map((_, i) => (
      <Page
        key={`${filename}-page-${i}`}
        ref={pageRefs[i]}
        containerRef={containerRef}
        width={width}
        height={height}
        pageIndex={i}
        pdfRef={pdfRef}
        showPageNumber={!isEdit}
        editable={isEdit}
        onLinkClick={handleLinkClick}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename, height, pageCount, pdfRef, width, isEdit]);

  useImperativeHandle(ref, () => {
    return {
      exportPagesEditData,
      importPagesEditData,
      loadPagesAIData,
    };
  });

  /**
   * Scroll page container by current page value
   */
  useEffect(() => {
    if (!containerRef.current || pageCount === 0) {
      return;
    }

    if (layout === "single") {
      // calculate left offset and apply to perform scroll
      const left = (containerRef.current.children[currentPage] as HTMLDivElement)?.offsetLeft;
      containerRef.current.style.transform = `translateX(-${left}px)`;
    } else {
      // reset scroll position in multi view mode
      containerRef.current.style.transform = "";
    }
  }, [currentPage, pageCount, layout]);

  return (
    <ContentWrapper ref={containerRef} layout={layout}>
      {pages}
    </ContentWrapper>
  );
});

ViewerPages.displayName = "ViewerPages";
