/**
 * External modules
 */
import React from "react";
import styled from "styled-components";
import { width } from "styled-system";

/**
 * Type modules
 */
import type { WidthProps } from "styled-system";

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const PopupWrapper = styled.div<WidthProps & { show: boolean }>`
  ${width}
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  background: #fff;
  padding: 8px 0;
  z-index: 20;
  box-shadow: 0px 2px 5px 0px #BEBEBE;
  visibility: ${({ show }) => show ? `visible` : `collapse`};
  opacity: ${({ show }) => show ? 1 : 0};
  transition-property: visibility, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  color: #0f172a;
`;

const MenuItemWrapper = styled.div`
  flex: 1;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;

  :hover {
    background: #f4f4f5;
  }
`;

interface PopupMenuProps extends WidthProps {
  opener: React.ReactNode;
  show: boolean;
  menu?: React.ReactNode[];
  onMenuItemClick?: (index: number) => void;
}

export const PopupMenu = (props: PopupMenuProps) => {
  const { width, show, opener, onMenuItemClick, menu = [] } = props;

  const handleClick = (index: number) => () => {
    if (onMenuItemClick) {
      onMenuItemClick(index);
    }
  };
  return (
    <Wrapper>
      {opener}
      <PopupWrapper width={width} show={show}>
        {menu.map((menuEl, i) => (
          <MenuItemWrapper key={`menu-${i}`} onClick={handleClick(i)}>{menuEl}</MenuItemWrapper>
        ))}
      </PopupWrapper>
    </Wrapper>
  );
};
