/**
 * Type modules
 */
import type { PDF } from "./pdf";

export type AIObjectType = "drawing_no" | "None";

export interface AIDrawingData<T> {
  page_index: number;
  drawing_no: string;
  size: {
    width: number;
    height: number;
  };
  bound: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  objects: {
    type: AIObjectType;
    x: number;
    y: number;
    w: number;
    h: number;
    data: T;
  }[];
}

export interface AIData<T = string> {
  version: number;
  meta: {
    filename: string;
    size: number;
    processed_at: string;
  };
  drawings: AIDrawingData<T>[];
}

export class DocumentDataManager<T = string> {
  data: AIData<T> | null;

  constructor(data: AIData<T> | null) {
    this.data = data;
  }

  setData(data: AIData<T> | null) {
    this.data = data;
  }

  getPageIndexByDrawingNo(drawingNo: string) {
    return this.data?.drawings.find((drawing) => drawing.drawing_no === drawingNo)?.page_index;
  }

  /**
   * Validate data is AI Data object.
   *
   * This function checks the property existence and type of data.
   *
   * @param data validation target
   * @returns the result of validation
   */
  static validateAIData(data: object) {
    if (!("version" in data) || typeof data.version !== "number") return false;
    if (!("meta" in data) || typeof data.meta !== "object" || data.meta == null) return false;
    if (!("drawings" in data) || typeof data.drawings !== "object" || data.drawings == null) return false;

    if (!("filename" in data.meta)) return false;
    if (!("size" in data.meta)) return false;
    if (!("processed_at" in data.meta)) return false;

    if (!(data.drawings instanceof Array)) return false;

    return true;
  }

  /**
   * Check the AI data compatibility.
   *
   * If version value is not 1, then it will return false.
   * Because current version of AI data format is 1.
   * @param data
   * @param pdfInstance
   * @returns the result of compatibility
   */
  static checkAIDataCompatibility<DataType>(data: AIData<DataType>, pdfInstance?: PDF) {
    if (!pdfInstance) {
      // console.log("No PDF instsance");
      return false;
    }
    if (data.version !== 1) {
      // console.log("Incorrect version");
      return false;
    }

    if (data.meta.filename !== pdfInstance.filename) {
      // console.log("Incorrect filename", data.meta.filename, ",", pdfInstance.filename);
      return false;
    }
    // if (data.meta.size !== pdfInstance.fileSize) return false;

    return true;
  }
}
