/**
 * External modules
 */
import React from "react";
import styled from "styled-components";

const FileSelectWrapper = styled.div`
  display: flex;
  margin: -4px -8px;

  & > label {
    flex: 1;
    cursor: pointer;
    padding: 4px 8px;
  }

  & > input[type="file"] {
    display: none;
  }
`;

interface Props {
  inputKey: string;
  accept?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const MenuFileSelectButton = (props: React.PropsWithChildren<Props>) => {
  return (
    <FileSelectWrapper>
      <label htmlFor={props.inputKey}>{props.children}</label>
      <input type="file" id={props.inputKey} accept={props.accept} onChange={props.onChange} />
    </FileSelectWrapper>
  );
};
