/**
 * External modules
 */
import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

/**
 * Internal modules
 */
import { filenameState } from "../../states/pdfium";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  jistify-content: center;
  background-color: #f4f4f5;
`;

const CurrentFile = styled.span`
  flex: 1;
  text-align: center;
  padding: 0.5rem; 0;
`;

export const ViewerHeader = () => {
  const filename = useRecoilValue(filenameState);

  return (
    <Wrapper>
      <CurrentFile>{filename ? `${filename} - ` : ''}DRAW Editor</CurrentFile>
    </Wrapper>
  );
};
